.container {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 800px;
  flex-direction: column;
}

@mainWidth: 1200px; // 定义一个变量

.tableBody {
  position: relative;
  width: @mainWidth;
  height: 500px;
}

.tableHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .opWrap {
    display: flex;
    gap: 10px;
    height: 60px;
    align-items: center;
    width: @mainWidth;
  }
}

.tableSpin {
  z-index: 99;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: z-index 0.3s;
  background-color: rgba(255, 255, 255, 0.8);
}

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
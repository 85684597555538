.container {
  background: #fff;
  padding: 16px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
// 展开状态阴影
.moreContainer {
  position: absolute;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.moreOpWrap {
  display: flex;
  gap: 18px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
}

// 表单项
// .fieldWrap {
//   display: inline-flex;
//   align-items: center;
//   gap: 8px;
// }

// 操作项
.actionList {
  display: inline-flex;
  gap: 15px;
  align-items: center;
}

.actionListEnd {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  // justify-content: flex-end;
  // width: 100%;
}

.divider {
  display: inline-block;
  width: 1px;
  height: 80%;
  // margin: 0 1px;
  vertical-align: middle;
  position: relative;
  border-inline-start: 1px solid #ebedf2;
}

// 搜索项
.fieldList {
  display: flex;
  flex-direction: column;

  .first {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      gap: 15px;
    }
  }
}

.innerFieldWrap {
  width: 100%;
  display: inline-flex;
}

.end {
  top: 36px;
  left: 0;
  right: 0;
  border-top: 1px solid #dae0e7;
  margin-top: 15px;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  // .horizontal{
  //   display: flex;
  //   align-items: center;
  //   gap: 8px;
  // }
  .vertical {
    display: flex;
    flex-direction: column;
  }

  .innerFieldWrap {
    display: flex;
    flex-direction: column;
  }

  .addon {
    border: none;
    background: none;
    height: 24px;
    margin-bottom: 3px;
    padding: 0;
  }
}

.selected {
  color: #66686c;
  font-size: 14px;
}

.clear {
  font-size: 14px;
  cursor: pointer;
  color: #1890ff;
}

.tagWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
  padding: 20px 0 10px 0;
}

.addon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  padding: 0 11px;
  color: #2e3033;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid #e1e2e5;
  transition: all 0.3s;
  line-height: 1;
  border-radius: 2px;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-inline-end: 0;
}

.setting {
  flex-shrink: 0;
}

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
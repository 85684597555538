.container {
  .sliderHidden {
    width: 0 !important;
    min-width: 0 !important;

    .sliderTrigger {
      // display: none;
      width: 0;
    }

    svg {
      z-index: 0;
      width: 0;
    }
  }

  :global(.ant-menu-title-content) {
    margin-inline-start: 4px !important;
  }

  :global(.ant-menu) {
    background-color: var(--bg-sider) ;
  }
  :global(.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline) {
    // background: #fff;
    background-color: var(--bg-sider) ;
  }

  :global(.ant-menu-inline .ant-menu-item) {
    margin-inline: 0;
    width: 100%;
  }

  :global(.ant-menu-item-selected) {
    &::before {
      position: absolute;
      left: 0;
      width: 3px;
      height: 100%;
      background-color: @primary-color;
      content: '';
    }
  }

  :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
    height: 100%;

    // 覆盖菜单栏滚动样式

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      background: hsl(0deg 0% 100% / 15%);
      border-radius: 3px;
      box-shadow: inset 0 0 5px rgb(37 37 37 / 5%);
    }

    ::-webkit-scrollbar-thumb {
      // background: hsl(0deg 0% 100% / 20%);
      background: rgb(0 0 0 / 12%);
      border-radius: 3px;
      box-shadow: inset 0 0 5px hsl(0deg 0% 100% / 5%);
    }
  }

  .sider {
    top: 0;
    left: 0;
    z-index: 99;
    height: 100%;
    padding-top: 64px;
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
    background-color: var(--bg-sider) !important;
    // transition: width 0.3s;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    margin: 16px;
    color: #524f4f;
  }
}

.popupMenu {
  :global(.ant-menu-item-selected) {
    &::before {
      position: absolute;
      left: 0;
      width: 3px;
      height: 100%;
      background-color: @primary-color;
      content: '';
    }
  }
}

.sliderTrigger {
  cursor: pointer;
  width: 100%;
  // height: 40px;
  padding: 10px 16px 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6e6e6e;
  font-size: 20px;

  // &:hover {
  //   color: #288dff;
  // }

  svg {
    width: 1em;
    height: 1em;
    /* stylelint-disable-next-line value-keyword-case */
    fill: currentColor;
  }
}

.btnCollapsed {
  justify-content: center;
}

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
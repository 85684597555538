.debug-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  //   background-color: #ffcc00;
  color: #333;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  font-size: 16px;
  width: 40px;
}
//   .wave-container {
//     position: relative;
//     height: 40px;
//     display: flex;
//     align-items: center;
//   }
//   .wave-button {
//     position: relative;
//     background-color: #333;
//     color: #fff;
//     border: none;
//     border-radius: 50%;
//     height: 40px;
//     width: 40px;
//     cursor: pointer;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     outline: none;
//     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
//   }
//   .wave-button::before {
//     content: '';
//     position: absolute;
//     top: -10px;
//     left: -15px;
//     right: -15px;
//     height: 15px;
//     background-color: #ffcc00;
//     border-radius: 50% 50% 0 0;
//   }
//   .wave-button::after {
//     content: '';
//     position: absolute;
//     top: -5px;
//     left: -10px;
//     right: -10px;
//     height: 10px;
//     background-color: #ffcc00;
//     border-radius: 50% 50% 0 0;
//   }

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
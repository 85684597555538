// 覆盖antd 组件样式

.ant-modal-title {
  color: #1e2330 !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  text-align: center;
}

.ant-modal-header {
  // margin-bottom: 32px !important;
  border-bottom: 0;
  border-radius: 16px;
}

.ant-modal-content {
  border-radius: 16px;
}

.ant-modal-footer {
  padding-right: 24px;
  padding-bottom: 24px;
  text-align: center !important;
  border-top: 0;

  button {
    width: 150px;
  }

  button:last-child {
    margin: 0 0 0 20px !important;
  }
}

.ant-popover-inner-content {
  padding: 20px 24px;
}

// .ant-btn {
// border-radius: 4px;
// height: 40px;
// min-width: 120px;
// }

// .ant-input-group-addon {
//   .ant-btn {
//     min-width: auto;
//   }
// }

// .ant-btn-default {
//   border: 1px solid @text-01-color;
// }
.ant-input-prefix {
  margin-right: 17px;
}

.ant-page-header-back {
  margin-right: 8px;
}

.ant-page-header-heading-title {
  font-weight: normal;
}

.ant-menu {
  svg {
    margin-right: 8px;
  }
}

.ant-menu-inline-collapsed {
  .ant-menu-item-icon {
    line-height: 60px !important;
  }
}

.ant-menu.ant-menu-inline-collapsed {
  > .ant-menu-item,
  > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 calc(50% - 26px / 2);
  }
}

.ant-tooltip-inner {
  width: fit-content;
  min-height: 32px !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #d4d4d5;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #d4d4d5;
}

.ant-affix {
  background-color: @white-color;
}

// .ant-dropdown-menu-item {
//   .anticon {
//     color: @placeholder-color;
//     font-size: 18px !important;
//   }

//   &:hover {
//     .anticon {
//       color: @text-01-color;
//     }
//   }
// }

.ant-dropdown-menu-item-disabled:hover {
  .anticon {
    color: @placeholder-color;
  }
}

.ant-form-item {
  margin-bottom: 16px;
}

// .ant-divider-vertical {
//   border-inline-start: 2px solid #e7e8ea;
// }

.ant-tree-node-selected {
  .ant-tree-title {
    color: @primary-color;
  }
}

// 表格样式统一修改
.ant-table-wrapper .ant-table-thead > tr > th {
  color: #333333;
  // font-weight: bold;
}

.ant-table-wrapper .ant-table {
  color: #666666;
  font-weight: normal;
}

// TODO: 会影响拖拽的样式，暂时先去掉
// 树单行展示，超出显示省略号
.ant-tree .ant-tree-node-content-wrapper {
  // overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  .ant-tree-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

// .ant-tree-drop-indicator {
//   border-bottom: 2px dashed #ff4d4f; /* 更粗的红色虚线 */
// }
// 去掉外面一层分割面板
.ant-splitter {
  box-shadow: none !important;
}
// 被下部挡住了，找时间去掉
.ant-splitter-bar-collapse-bar-end {
  z-index: 1000 !important;
}

.container {
  // padding: 15px;
  padding: 4px 12px
}

.footer {
  // position: absolute;
  // right: 0;
  // bottom: 0;
  // left: 0;
  // z-index: 99;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 80px;
  background-color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  // box-shadow: 0 4px 24px 0 rgb(0 0 0 / 10%);

  button {
    width: 150px;
  }
}

// :global(.ant-divider-horizontal) {
//   margin: 0px;
//   padding: 4px 0px;
// }
@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
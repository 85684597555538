@right-width: 790px;

.title-css {
  color: #2e3033;
  font-weight: 600;
  font-size: 42px;
}

.login-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100vh;

  .login-bg {
    flex: 1;
    // background: no-repeat center/cover url('~@/assets/loginBg.png');
  }

  // .login-bg-color{
  //   background-color: #dde5f0;
  // }

  .luban-logo {
    position: absolute;
    top: 24px;
    left: 32px;
  }

  .login-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: @right-width;
    padding-left: 157px;
  }

  .login-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    transition: all 0.3s;

    span {
      margin-left: 8px;
      font-weight: 600;
      font-size: 40px;
    }
  }

  .login-sub-title,
  .login-title {
    align-self: flex-start;
  }

  .login-sub-title {
    margin-top: 87px;
    margin-bottom: 9px;
    color: #737880;
    font-size: 20px;
  }

  .login-title {
    margin-bottom: 56px;
    .title-css;
  }

  .login-form {
    width: 468px;

    :global(.anticon) {
      color: @placeholder-color;
    }
  }

  .captcha {
    height: 48px;
    background-color: aquamarine;
  }

  .login-form-button {
    width: 100%;
  }

  .login-select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: @right-width;
    height: 100%;
    padding: 0 119px;
    background: @white-color;

    .title {
      text-align: center;
      .title-css;
    }

    :global(.ant-table-wrapper) {
      overflow: auto;
    }
  }

  .select-btn {
    display: flex;
    justify-content: center;
    margin: 40px 0;

    button {
      width: 150px;
    }

    button:last-child {
      margin-left: 20px;
    }
  }

  .login-tool {
    display: flex;
    justify-content: space-between;
  }
}

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
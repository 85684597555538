.drawer {
  z-index: 999;
  position: fixed;
  top: calc(64px);
  left: 0;
  width: 208px; /* 可以根据需要调整宽度 */
  height: calc(100vh - 64px);
  background: #fff;
  transition: transform 0.3s ease;
}

.drawer.open {
  transform: translateX(0);
  box-shadow: 4px 0px 6px 0px #b0b0b066;
}

.drawer.close {
  box-shadow: none;
  transform: translateX(-100%);
}

.drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
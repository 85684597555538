.actionWrap {
  display: flex;
  align-items: center;
  height: 48px;
  // padding: 0 12px;
  padding-right: 12px;
  cursor: pointer;
  transition: all 0.3s;
}

.username {
  padding-left: 5px;
  color: var(--text-header-color);
}
.iconDown{
  color: var(--text-header-color);

}

.labelWrap {
  .checkIcon {
    padding-left: 5px;
    color: #288dff;
    font-size: 13px ;
  }
}

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
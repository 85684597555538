@import url('./atom.less');
@import url('./global.less');
@import url('./custom-antd.less');
@import url('./config.less');

#root,
body,
html {
  height: 100%;

  // 最小宽度
  min-width: 1300px;
  overflow-x: auto;
  // overflow-y: hidden;
}

#root > * {
  color: @text-01-color;
  font-size: @font-text;
}

body {
  /* Safari */ /* Firefox */ /* IE 10+/Edge */
  // user-select: none;
}

.mainColor {
  color: @primary-color;
}

// .mdi {
//   display: inline-block;
//   align-items: center;
//   background-color: currentColor;
//   font-size: 18px;
//   width: 1em;
//   height: 1em;
// }

// .mdi-lightbulb-outline {
//   width: 16px; /* 设置合适的尺寸 */
//   height: 16px;
//   background-image: url('~@/assets/svg/slickGridFold.svg');
//   background-size: contain;
//   background-repeat: no-repeat;
//   cursor: pointer;
// }

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
.menu-icon {
  width: 30px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  // border: 1px solid black;
  margin: 0 20px 0 15px;
}

.menu-icon span {
  display: block;
  height: 3px;
  background-color: var(--text-header-icon-color);
  transition: all 0.3s ease-out 0s;
}



.menu-icon.open span:nth-child(1) {
  transform: translateY(8.9px) rotate(45deg);
}

.menu-icon.open span:nth-child(2) {
  opacity: 0;
}

.menu-icon.open span:nth-child(3) {
  transform: translateY(-8.9px) rotate(-45deg);
}

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
.container {
  // background-color: #f7f9fb;
  height: 100%;
}
.tableWrapper {
  width: 600px;
}
// 表单项
// .fieldWrap {
//   display: inline-flex;
//   align-items: center;
//   gap: 8px;
// }

.fieldList {
  display: flex;
  flex-direction: column;

  .first {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
}

.fieldItem {
}

.innerFieldWrap {
  width: 100%;
  display: inline-flex;
}

.end {
  border-top: 1px solid #dae0e7;
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .innerFieldWrap {
    display: flex;
    flex-direction: column;
  }
  .addon {
    border: none;
    background: none;
    height: 24px;
    margin-bottom: 3px;
    padding: 0;
  }
}

.addon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  padding: 0 11px;
  color: #2e3033;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid #e1e2e5;
  border-radius: 2px;
  transition: all 0.3s;
  line-height: 1;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-inline-end: 0;
}

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
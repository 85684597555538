:root {
  // CSS 间距变量标准化方案
  --spacing-base: 4px; /* 基础单位 */
  --spacing-2: calc(var(--spacing-base) * 2); /* 8px */
  --spacing-3: calc(var(--spacing-base) * 3); /* 12px */
  --spacing-4: calc(var(--spacing-base) * 4); /* 16px */
  --spacing-8: calc(var(--spacing-base) * 8); /* 32px */

  // 导航头部背景色
  --bg-header: #ffffff ;
  // 导航头部字体颜色
  --text-header-color: #2E3033 ;
  // 导航头部小标题颜色
  --text-header-sub-color: #666 ;
  // 导航头部切换菜单图标颜色
  --text-header-icon-color: #333333;
  // 导航皮肤切换图标颜色
  --header-skin-icon-color: #8a9099;

  // 左侧菜单背景色
  --bg-sider: #ffffff;

}

[data-theme='light'] {
  // 导航头部背景色
  --bg-header: #ffffff ;
   --text-header-color: #2E3033 ;
  --text-header-sub-color: #666 ;
  --text-header-icon-color: #333333;
  --bg-sider: #ffffff;
  --header-skin-icon-color: #8a9099;

}

[data-theme='business'] {
  // 导航头部背景色
  --bg-header: #288DFF ;
  --text-header-color: #ffffff ;
  --text-header-sub-color: #fff ;
  --text-header-icon-color: #fff;
  --bg-sider: #f4f6fb;
  --header-skin-icon-color: #fff;
}

.hidden {
  display: none !important;
}

.disableCursor {
  cursor: not-allowed !important;
}

.disablePointerEvents {
  pointer-events: none !important;
}

.disable {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.pointer {
  cursor: pointer;
}

// 宽度
.w-full {
  width: 100%;
}

.list-table-content {
  margin-top: 16px;

  .ant-tabs-tab {
    padding: 0 0 12px;
  }
}

.keywords-input {
  background: #f5f7fa;

  .ant-input {
    background: #f5f7fa;
  }
}

.handle-icon-cell {
  .anticon {
    color: @placeholder-color;
    font-size: 18px !important;
    cursor: pointer;

    &:hover {
      color: @text-01-color;
      cursor: pointer;
    }
  }

  svg:hover {
    g,
    path,
    rect,
    circle {
      fill: @text-01-color !important;
    }
  }
}

.table-header-title {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 20px;
}

.spin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
}

.cell-ellipsis-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* 控制最大行数为5 */
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
}

.cell-ellipsis-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5; /* 控制最大行数为5 */
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
}

.default-text {
  color: @placeholder-color;
}

.full-width {
  a {
    display: inline-block;
    width: 100%;
  }

  span {
    display: inline-block;
    width: 100%;
    text-align: right;
  }
}

.text-link {
  &:hover {
    color: @primary-color;
    cursor: pointer;
  }
}

.can {
  // user-select: text; /* Safari */
  // user-select: text; /* Firefox */
  // user-select: text; /* IE 10+/Edge */
  user-select: text;
}

// 表格操作列样式
.handle-cell {
  display: flex;
  align-items: center;
  // justify-content: start;
  justify-content: center;

  // button {
  //   height: 36px;
  // }
}

/* stylelint-disable */
*:fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  background-color: #fff;
}

// 表格编辑样式
.editable-row:hover {
  .editable-cell-value-wrap {
    height: 25px;
    padding: 0 7px;
    border: 1px solid #d9d9d9;
    // border-radius: 2px;
    // min-height: 20px;
    cursor: pointer;
  }
}

// td 缩小paddding
.tightTable {
  .ant-table-tbody > tr > td {
    padding: 5px 5px !important;
  }
}

.full {
  width: 100%;
  height: 100%;
  min-height: 200px;
}

.hide {
  display: none;
}

.highlight {
  color: #288dff !important;
}

// 表格列可以点击时，加的特定标记
.a_link_color {
  color: #288dff !important;
  // cursor: pointer !important;
}

// 拖动遮罩样式，一般用于拖动左侧菜单的事件监听
.resize-mask {
  background: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: col-resize;
}

.mainColor {
  color: @primary-color;
}

.linkBtn {
  cursor: pointer;
  color: @primary-color;
}

.ant-table-thead > tr > th {
  text-align: center !important;
}
.break {
  word-break: break-all !important;
  // 超出长度省略
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  // width: 150px !important;

  display: -webkit-box; /* 必须组合使用 */
  -webkit-box-orient: vertical; /* 必须组合使用 */
  -webkit-line-clamp: 1 !important; /* 限制显示的行数 */
  overflow: hidden !important; /* 隐藏超出的内容 */
}

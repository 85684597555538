.layout-header {
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: @HeightHeader;
  line-height: @HeightHeader;
  border-bottom: 1px #0000000f solid;
  background-color: var(--bg-header) !important;

  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.3s;

    span {
      margin-left: 8px;
      font-weight: 600;
      font-size: 20px;
    }

    .header_divider {
      margin: 0 20px;
      height: 20px;
    }

    .enterprise_name {
      color: var(--text-header-sub-color);
      font-size: 14px;
      font-weight: 500;
    }

    .title{
      color: var(--text-header-color);
    }
  }

  .mid {
    display: flex;
    flex: 1; // 这个属性会造成某些分辨率下的显示问题。会展示tabs的省略号，然后界面dom不停闪动。rc=tabs的bug导致。换成width: 100%解决
    // width: 100%;
    // align-self: flex-end;
    margin-left: 14px;

    .icon {
      display: flex;
      color: @placeholder-color;
      font-size: 18px;

      &:hover {
        color: @text-01-color;
      }
    }

    :global(.ant-tabs) {
      margin-bottom: 0;
      margin-left: 57px;
    }

    :global(.ant-tabs-tab) {
      height: @HeightHeader;
    }

    :global(.ant-tabs-nav) {
      margin-bottom: 0;
    }
  }

  .right {
    display: flex;
    align-items: center;
    padding-right: 20px;
  }

  .skinIcon {
    cursor: pointer;
    color: var(--header-skin-icon-color);

    &:hover {
      opacity: 0.8;
    }
  }
}

.skinSwitch {
  display: flex;
  gap: 20px;
  color: #999999;

  .themeItem{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .check {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.iconWrap {
  cursor: pointer;
  position: relative;
  display: flex;
}

.skinPopover {
  :global(.ant-popover-inner) {
    // border-radius: 0;
    // padding: 8px 8px 4px 8px;
    padding: 8px 10px 6px 10px;
  }

  &:hover {
    opacity: 0.9;
  }
}

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
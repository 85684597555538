// 全局原子样式
// 可参考：https://www.tailwindcss.cn/docs/justify-content




.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

// Flex Direction
.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

// Justify Content

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

// Align Items

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

// 文本对齐
.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

// 外边距
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

// 内边距
.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-10 {
  padding-left: 10px;
}

// 光标
.cursor-pointer {
  cursor: pointer;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-no-drop {
  cursor: no-drop;
}

// 宽度
.w_150 {
  width: 150px;
}

// 颜色
.green {
  color: green;
}
.red {
  color: red !important;
}
.black {
  color: #000 !important;
}

.c1f1f1f {
  color: #1f1f1f;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index--1 {
  z-index: -1 !important;
}


.pointer-none {
  pointer-events: none;
}
// NumberRange.less
@number-range-prefix: number-range;

// 设计变量
@primary-color: #1890ff;
@border-color-base: #d9d9d9;
@text-color: rgba(0, 0, 0, 0.85);
@transition-duration: 0.3s;

.@{number-range-prefix} {
  display: inline-flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #e1e2e5;
  // border-radius: 4px;
  border-radius: 2px;
  width: 100%;

  :global {
    .ant-input-number-input {
        padding: 0px;
    }

    .ant-input-number {
      border: none;
      text-align: center;
    }

    .ant-input-number-outlined:focus-within {
      box-shadow: none;
    }
  }

  &:hover:not(.@{number-range-prefix}-disabled), &-focused {
    border-color: @primary-color;

    .@{number-range-prefix}-clear {
        opacity: 1;
    }
  }

  &-input-group {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    // padding: 12px;
    padding: 6px 11px;
    // border: 1px solid @border-color-base;
    // transition: all @transition-duration;
    // background: white;
    width: 100%;
    height: 34px;
  }

  &-input {
    // width: 90px;
    width: 40%;
    border: none !important;
    box-shadow: none !important;
    background: transparent;
    color: @text-color;

    &:focus {
      box-shadow: none !important;
    }

    &-disabled {
      background: #f5f5f5;
      cursor: not-allowed;
    }
  }

//   &-separator {
//     margin: 0 8px;
//     color: @text-color;
//     opacity: 0.6;
//   }

  &-clear {
    position: absolute;
    top: 50%;
    inset-inline-end: 8px;
    color:  fade(@text-color, 25%);
    line-height: 1;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s, color 0.2s;

    &:hover {
        color:  fade(@text-color, 45%);
    }
  }

  // 尺寸控制
  &-small &-input-group {
    padding: 0px 7px;
    height: 24px;
    
    .@{number-range-prefix}-input {
      width: 80px;
      font-size: 12px;
    }
  }

  &-large &-input-group {
    padding: 16px;
    height: 40px;
    
    .@{number-range-prefix}-input {
      width: 110px;
      font-size: 16px;
    }
  }

//   // 移动端适配
//   @media (max-width: 575px) {
//     &-input-group {
//       height: 44px;
//     }
    
//     &-input {
//       font-size: 16px;
//     }
//   }
}

// .rangeWrapper {
//   display: inline-flex;
//   border-width: 1px;
//   border-style: solid;
//   border-color: #e1e2e5;

//   :global {
//     .ant-input-number {
//       border: none;
//       text-align: center;
//     }

//     .ant-input-number-outlined:focus-within {
//       box-shadow: none;
//     }
//   }
// }

@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
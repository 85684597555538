// 业务样式配置

// 弹窗
.btn_footer_w {
  width: 150px;
}

// 表格操作按钮样式，原生样式会导致行高过高
// .tableOpBtn {
//   height: auto;
//   padding: 0 7px;
//   font-size: 14px;
//   color: #1677ff;
//   cursor: pointer;
//   outline: none;
//   position: relative;
//   display: inline-block;
//   font-weight: 400;
//   white-space: nowrap;
//   text-align: center;
//   background-image: none;
//   background-color: transparent;
//   transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
//   user-select: none;
//   touch-action: manipulation;
//   line-height: 1.5714285714285714;

//   &:hover {
//     color: #69b1ff;
//     background-color: transparent;
//   }
// }

.tableOpBtn {
  height: auto !important;
  padding: 0 7px !important;
  border: none !important;
}

// 表格名称链接点击。hover变色
.tableNameLink {
  cursor: pointer;

  &:hover {
    color: #69b1ff;
  }
}

// 表格按钮点击文字样式
.tableOpBtnClick {
  cursor: pointer;
  color: #3090fc;
}

// 表格操作单元格样式
.tableOpCell {
  // 表格操作按钮样式，原生样式会padding 较大。link文字不需要padding
  .ant-btn-link {
    padding: 0;
  }
}

// 抽屉关闭按钮
.drawer-close-icon {
  color: @placeholder-color;
  cursor: pointer;
}

// luckysheet 光标失效
.luckysheet-input-box {
  z-index: 10000000000; /* 具体的值根据实际情况而定，需要比下面的其他元素的z-index大 */
}


.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.item_wrap{
   width: 100%;
   display: flex;
   justify-content: center;
  
    
    &.default{
        padding-top: 8px;
        // padding: 8px 9px 0;
    }
}

.item{
    width: 180px;
    height: 35px;
    border-radius: 4px;
    padding:0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
   

    .left{
        display: flex;
        align-items: center;
    }

    &.default{
        // margin: 8px;
    }
    
    &.active{
        background-color: #D6E2FB;
    }

    &:hover{
        background-color: rgba(214, 226, 251, 0.5);
    }
}

.bottom{
    .item_wrap{
        padding-bottom: 3px;
    }
}

.divider{
    margin: 8px 0;
}

.icon{
    // width: 20px;
    padding-right: 10px;
}
@HeightHeader: 64px;@colorBgLayout: #f5f5f5;@colorBgContainer: #ffffff;@gray-05-bg: #f4f4f4;@gray-10-bg: #e9eaea;@gray-20-bg: #d4d4d5;@danger-bg: #ffe5e5;@font-text: 14px;@primary-color: #288dff;@white-color: #fff;@required-color: #d40000;@empty-row-color: #d9d9d961;@link-color: #0082fa;@link-hover-color: #0068c8;@disabled-color: #00000040;@text-01-color: #2e3033;@text-02-color: #535455;@text-03-color: #7e7f80;@placeholder-color: #a8abb3;@sidebar-menu-radius: 8px;@body_height: calc(100vh - 64px - 16px - 16px - 38px);
@import '@slickgrid-universal/common/dist/styles/sass/variables';
/* make sure to add the @import the SlickGrid Bootstrap Theme AFTER the variables changes */
// @import '@slickgrid-universal/common/dist/styles/sass/slickgrid-theme-bootstrap.scss';
@import url('./slickgrid-theme.scss');

// @import '@slickgrid-universal/common/dist/styles/sass/slickgrid-theme-material.scss';
// @import '@slickgrid-universal/common/dist/styles/sass/slickgrid-theme-salesforce.scss';
// $slick-checkbox-icon-unchecked-svg-path: "M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" !default;

// 表格主体颜色，包括行，单元格，和编辑单元格
$cell-color: #ffffeb;
// 选中背景颜色
$selected-color: #dae8f1;

// border color
$cell-border-color: #f8fafb;

// 覆盖变量
:root {
  .slickgrid-num-left-cls {
    text-align: left;
  }
  .slickgrid-num-right-cls {
    text-align: right;
  }
  .slickgrid-checkbox-center-cls {
    text-align: center;
  }

  // 单元格边框的样式
  // border-top: var(--slick-cell-border-top, 1px solid #dddddd);
  // border-bottom: var(--slick-cell-border-bottom, 1px transparent);
  // border-left: var(--slick-cell-border-left, 1px transparent);
  // border-right: var(--slick-cell-border-right, 1px transparent);
  // --slick-cell-border-top: 1px solid #dddddd;
  // --slick-cell-border-bottom: 1px solid #dddddd;
  // --slick-cell-border-left: 1px solid #dddddd;

  // 表头颜色
  --slick-cell-border-right: 1px solid #ebedf2;

  --slick-header-border-bottom: 1px solid #f0f0f0;

  --slick-cell-display: table-cell;
  --slick-container-border-top: 1px solid #ebedf2;
  --slick-container-border-left: 1px solid #ebedf2;
  --slick-container-border-right: 1px solid #ebedf2;

  // 表头右边框
  --slick-header-column-border-right: 1px solid #ebedf2;
  // --slick-container-border-botom:none;

  --slick-cell-border-top: none;
  --slick-cell-border-bottom: 1px solid #ebedf2;
  // --slick-container-border-bottom: none;
  --slick-header-background-color: #f8fafb; // 头部样式

  // 单元格行
  --slick-row-selected-color: #{$selected-color};
  --slick-cell-odd-background-color: #{$cell-color};

  // 单元格样式
  --slick-cell-odd-background-color: #{$cell-color};

  // 单元格勾选框样式
  --slick-checkbox-icon-border: 1px solid #d6d4d4;

  // 单元格激活样式
  --slick-cell-active-box-shadow: inset 0 0 0 1px rgb(75, 137, 255); // 选中cell的shadowbox
  --slick-cell-active-border: inherit; // 选中cell的边框

  // 单元格编辑
  --slick-text-editor-border: none;
  // --slick-text-editor-padding-left:none;
  // --slick-text-editor-padding-top:none;
  // --slick-text-editor-padding-right:none;
  // --slick-text-editor-padding-bottom:none;
  --slick-text-editor-margin-left: none;
  --slick-text-editor-margin-right: none;
  --slick-text-editor-margin-top: none;
  --slick-text-editor-margin-bottom: none;

  --slick-text-editor-background: transparent;

  // 表头高度
  --slick-header-column-height: 22px;

  --slick-checkbox-icon-unchecked-svg: url('data:image/svg+xml;utf8,%3Csvg viewBox="0 0 24 24" display="inline-block" height="1em" width="1em" vertical-align="text-bottom" xmlns="http://www.w3.org/2000/svg" %3E%3Cpath fill="currentColor" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"/%3E%3C/svg%3E') !important;
  // --slick-checkbox-icon-checked-svg: url('data:image/svg+xml;utf8,%3Csvg viewBox="0 0 24 24" display="inline-block" height="1em" width="1em" vertical-align="text-bottom" xmlns="http://www.w3.org/2000/svg" %3E%3Cpath fill="currentColor" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"/%3E%3C/svg%3E');
  // 单元格选中样式
  // --slick-checkbox-icon-unchecked-svg: url(data:image/svg+xml;utf8,%3Csvg viewBox="0 0 24 24" display="inline-block" height="1em" width="1em" vertical-align="text-bottom" xmlns="http://www.w3.org/2000/svg" %3E%3Cpath fill="currentColor" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"/%3E%3C/svg%3E) !important;
  // --slick-checkbox-icon-container-bg-color: #fff;

  // --slick-cell-active-border,

  // --slick-row-selected-color: #ffffeb ; // 选中cell的颜色

  // box-shadow: var(--slick-cell-active-box-shadow, inset 0 0 0 1px #aaaaaa);

  // --slick-cell-active-box-shadow: none;
  // --slick-text-editor-focus-box-shadow: none;

  // $slick-input-focus-box-shadow: 0 0 0 0.25rem #0d6efd40;

  // --slick-header-menu-display: inline-block;
  // --slick-primary-color-dark: pink;
  // --slick-header-filter-row-border-bottom: 2px solid pink;
  // border-right: 1px solid #ccc;
  // 单元格边框右边样式

  // box-shadow: var(--slick-text-editor-focus-box-shadow, 0 0 0 0.25rem rgba(13, 110, 253, 0.2509803922));
  // cell padding
  // --slick-cell-padding: 2px;

  // --slick-cell-border-width: 0 1px 1px 0;
  // --slick-cell-border-color: #d4d4d4;
  // --slick-cell-border-left: 1px solid #ccc;
}

.slick-column-name .icon-checkbox-container div.mdi.mdi-icon-uncheck,
.slick-headerrow-column.checkbox-header .icon-checkbox-container div.mdi.mdi-icon-uncheck,
.slick-cell-checkboxsel .icon-checkbox-container div.mdi.mdi-icon-uncheck {
  // --slick-checkbox-icon-unchecked-svg: url(data:image/svg+xml;utf8,%3Csvg viewBox="0 0 24 24" display="inline-block" height="1em" width="1em" vertical-align="text-bottom" xmlns="http://www.w3.org/2000/svg" %3E%3Cpath fill="currentColor" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"/%3E%3C/svg%3E);
  // --slick-checkbox-icon-unchecked-svg: url('data:image/svg+xml;utf8,%3Csvg viewBox="0 0 24 24" display="inline-block" height="1em" width="1em" vertical-align="text-bottom" xmlns="http://www.w3.org/2000/svg" %3E%3Cpath fill="currentColor" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"/%3E%3C/svg%3E') !important;
  // --slick-checkbox-icon-unchecked-svg: url('data:image/svg+xml;utf8,%3Csvg viewBox="0 0 24 24" display="inline-block" height="1em" width="1em" vertical-align="text-bottom" xmlns="http://www.w3.org/2000/svg" %3E%3Cpath fill="currentColor" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"/%3E%3C/svg%3E') !important;
  // --slick-checkbox-icon-unchecked-svg: url('data:image/svg+xml;utf8,%3Csvg viewBox="0 0 24 24" display="inline-block" height="1em" width="1em" vertical-align="text-bottom" xmlns="http://www.w3.org/2000/svg"%3E%3C/svg%3E')
  --slick-checkbox-icon-unchecked-svg: url('data:image/svg+xml;utf8,%3Csvg viewBox="0 0 24 24" display="inline-block" height="1em" width="1em" vertical-align="text-bottom" xmlns="http://www.w3.org/2000/svg"%3E%3Crect width="24" height="24" fill="white"/%3E%3C/svg%3E');
  mask: var(--slick-checkbox-icon-unchecked-svg) no-repeat !important;
  background-color: #fff;
}

// 覆盖样式
.slickgrid-container .slick-row .slick-cell {
  background-color: $cell-color;
  user-select: none;
}

// 允许编辑样式
.slickgrid-container .slick-row .slickgrid-edit {
  background-color: #fff !important;
  cursor: text;
}

// 不允许编辑 cursor
.slickgrid-container .slick-row .slickgrid-edit-disabled-cursor {
  cursor: default;
}

.slickgrid-container .slick-row.odd .slick-cell {
  background: inherit;
}

.slickgrid-container .slick-row.odd .slick-cell.slickgrid-edit-disabled {
  background-color: #f5f5f5 !important;
}

.slickgrid-container .slick-row .slickgrid-edit-disabled {
  background-color: #f5f5f5 !important;
}

.slickgrid-container .slick-row.odd .slick-cell.slickgrid-selected {
  background-color: #daecfd !important;
}

.slickgrid-container .slick-row .slickgrid-selected {
  background-color: #daecfd !important;
}

// .slickgrid-container .slick-row .dw_bg_color {
//   background-color: #f4c9b9 !important;
// }
// .slickgrid-container .slick-row .dx_bg_olor {
//   background-color: #fffdde !important;
// }
// .slickgrid-container .slick-row .fbfx_bg_color {
//   background-color: #ddb4b4 !important;
// }
// .slickgrid-container .slick-row .qd_bg_color {
//   background-color: #bcc3ea !important;
// }

.slickgrid-container .slick-row .dx_bg_olor {
  background-color: rgba(243, 240, 193, 0.5) !important;
  // background-color: rgba(255, 253, 225, 1) !important;
  // background-color: rgba(255, 253, 222, 0.3) !important;
  // background-color: #F8f6d5 !important;
}

.slickgrid-container .slick-row .dw_bg_color {
  background-color: rgba(243, 240, 193, 0.5) !important;
  // background-color: rgba(255, 253, 225, 1) !important;
  // background-color: rgba(244, 201, 185, 0.3) !important;
  // background-color: #FFfde1 !important;
}

.slickgrid-container .slick-row .fbfx_bg_color {
  background-color: rgba(248, 246, 213, 0.5) !important;
  // background-color: rgba(244, 201, 185, 0.3) !important;
  // background-color: rgba(221, 180, 180, 0.3) !important;
  // background-color: #F3f0c1 !important;
}

.slickgrid-container .slick-row .qd_bg_color {
  background-color: rgba(255, 253, 225, 0.5) !important;
  // background-color: rgba(243, 240, 193, 1) !important;
  // background-color: rgba(188, 195, 234, 0.3) !important;
  // background-color: rgba(188, 195, 234, 0.3) !important;
}

// export const dwColorClassName = 'dw_bg_color'
// export const dxColorClassName = 'dx_bg_olor'
// export const fbfxColorClassName = 'fbfx_bg_color'
// export const qdColorClassName = 'qd_bg_color'

// export const IndicatorColorMap = {
//   'dw': '#fffdde',
//   'dx': '#f4c9b9',
//   'fbfx': '#ddb4b4',
//   'qd': '#bcc3ea',
// }

// 单元格选中背景颜色
.slickgrid-container .grid-canvas .slick-cell.selected {
  background-color: #daecfd !important; // rgba(218, 236, 253, 0.5) !important;
}

// 隐藏总计
// .slickgrid-container .slick-group-totals{
//   display: none;
// }

.slickgrid-container .slick-group > .slick-cell {
  text-align: left;
}

// 选中范围样式
.slickgrid-container .slick-range-decorator {
  border: 2px solid #69b1ff !important;
}

// 单元格编辑
.slick-cell.selected input.editor-text {
  background-color: $selected-color;
}

.slickgrid-container .slick-row .slick-cell.active {
  border-top: var(--slick-cell-border-top, 1px solid #dddddd);
  border-bottom: var(--slick-cell-border-bottom, 1px transparent);
  border-left: var(--slick-cell-border-left, 1px transparent);
  border-right: var(--slick-cell-border-right, 1px transparent);

  // padding: 0;
}

.slick-cell input.dual-editor-text,
.slick-cell input.editor-text {
  border: none !important;
  outline: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  height: auto !important;
  scrollbar-color: #fff !important;
  box-shadow: none !important;
}

/* 操作列 */
.gb {
  .ant-btn-link {
    color: #1677ff !important;

    &:hover {
      color: #69b1ff !important;
      background: transparent;
    }
  }
  .ant-btn-disabled {
    cursor: not-allowed !important;
    color: rgba(0, 0, 0, 0.25) !important;
    &:hover {
      color: rgba(0, 0, 0, 0.25) !important;
      background: transparent;
    }
  }
}

// 树形表格操作
.opwrap {
  display: flex;
  justify-content: center;
  gap: 8px;
}

// 表格竖线
.tree-vertical-line {
  position: relative;
  display: inline-block;
  width: 15px;
  // border-right: 1px dashed #ccc;
  height: 100%;
  pointer-events: none;

  &::before {
    position: absolute;
    top: -3px;
    left: 15px;
    bottom: -3px;
    border-inline-end: 1px dashed #d9d9d9;
    content: '';
  }
}

.slickgrid_cutom_value {
  pointer-events: none;
  vertical-align: top !important;
}

// .slickgrid_cutom_value_wrap{
//   display: flex;
//   align-items: center;
//   overflow-x: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }
.slickgrid_struct_dx {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
}
.slickgrid_struct_dx .setting {
  display: flex;
  align-items: center;
  // padding-left: 10px;
  align-self: flex-end;
}
.slickgrid_struct_dx .setting .tag {
  display: flex;
  padding: 2px 3px;
  border-radius: 3px;
  // margin: 0 5px;
  margin-right: 10px;
  // background-color: #1677ff;
}

.slickgrid_struct_dx .setting .img {
  cursor: pointer;
  display: flex;
  padding: 2px;
  padding-right: 6px;
}
.slickgrid_cutom_tr_line_and_name {
  display: flex;
  height: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  flex: 1;
  text-overflow: ellipsis;
}
.slickgrid_cutom_value_tip {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.slickgrid_cutom_tr_wrap_collapsed {
  display: flex;
  align-items: center;
}
.slickgrid_cutom_line_wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slickgrid_cutom_toggle {
  vertical-align: top !important;
}

// 第一列数字列的样式
.slickgrid_column_number {
  background-color: #f8fafb !important;

  &.selected {
    background-color: $selected-color !important;
  }
}

// 单元格编辑单选的样式
.slickgrid_custom_select.active {
  .ms-choice {
    // background-color: $selected-color ;
    background-color: transparent;
    border: none;
    box-shadow: none;

    &:focus,
    &:active {
      box-shadow: none;
      background-color: $selected-color;
      border: none;
    }
  }
}

// 列头高亮
.slick-header-column.active {
  background-color: #dae8f1 !important;
}

// 隐藏表头 的下拉菜单和排序
.slick-sort-indicator {
  display: none !important;
}

.slick-sort-indicator-numbered {
  display: none !important;
}

.slick-header-menu-button {
  display: none !important;
}

.slickgrid-container .slick-row .slick-cell.frozen:last-child,
.slickgrid-container .slick-footerrow-column.frozen:last-child {
  border-right: var(--slick-frozen-border-right, 1px solid #dae8f1); // #a5a5a5
}

.slickgrid-container .slick-header-column.frozen:last-child {
  border-right: var(--slick-frozen-header-row-border-right, 1px solid #dae8f1); // #a5a5a5
}

// 覆盖slickgrid 编辑器单选样式
div[data-name^='editor-'].ms-drop {
  .icon-checkbox-container {
    width: 0 !important;
    visibility: hidden !important;
  }
  li.selected {
    background-color: #eee;
  }
}

// ms-drop 样式
.ms-drop {
  font-size: 14px !important;
  margin-left: -3px !important;
  /* 您在此处添加您需要的样式 */
  // .icon-checkbox-container {
  //   width: 0 !important;
  //   visibility: hidden !important;
  // }

  ul {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  li.highlighted {
    // border: none !important;
    border-color: transparent !important;
    background-color: #eee !important;
  }

  // 下拉选中的样式
  li.selected {
    background-color: #dae8f1 !important; // #eee
  }
}

.search-filter :focus {
  outline: none;
}

.slickgrid-container input[type='checkbox'] {
  outline: none !important;
}

// 规则相关
.rule_wrap {
  display: block;
  flex-direction: column;
  flex-wrap: wrap;
}
.rule_item {
  // display: flex;
  // position: relative;
  margin: 0 2px;
  display: inline-flex;
  position: relative;
  font-size: 12px;
  background: #5bb69f;
  border-radius: 5px;
  padding-left: 3px;

  .close {
    cursor: pointer;
    padding-left: 3px;
    // position: absolute;
    // right: -5px;
    // top: -5px;
  }
}

// grid checkbox
.grid_cell_checkbox_container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.slick-column-name,
.slick-sort-indicator {
  float: none;
  margin-bottom: 100px;
}

.slickgrid-container .slick-pane-left.frozen {
  border-right: var(--slick-frozen-border-right, 1px solid #fff); // #a5a5a5
}

// .slick-header-column-active {
//   .slick-resizable-handle {
//     background-color: red;
//     height: 100px;
//   }
// }

// .slickgrid-container .slick-header-columns .slick-header-column .slick-resizable-handle {
//   &::after {
//     content: '';
//     position: absolute;
//     right: -5px;
//     top: -5px;
//     width: 10px;
//     height: 200px;
//     border: 1px solid #000;
//     background-color: red;
//     cursor: col-resize;
//     z-index: 9999;
//     clip-path: inset(0 0 50px 50px); /* 控制内容裁剪的范围 */

//   }
// }
